<template>
  <div>
    <md-table md-card table-header-color="blue" :value="userResources">
      <md-table-toolbar>
        <div class="md-toolbar-section-start">
          <h1 class="md-title">Список ресурсов пользователя</h1>
        </div>

        <div class="md-toolbar-section-end">
          <md-button
            class="md-raised md-primary"
            @click="showEditResourcesCountModal = true"
          >
            Изменить количество
          </md-button>
        </div>
      </md-table-toolbar>

      <md-table-empty-state md-label="Список пуст" />

      <md-table-row slot="md-table-row" slot-scope="{ item }">
        <md-table-cell md-label="Тип предмета">
          {{ itemTypesById[item.itemTypeId] }}
        </md-table-cell>

        <md-table-cell md-label="Значение">
          {{ item.value }}
        </md-table-cell>
      </md-table-row>
    </md-table>

    <EditResourcesCountModal
      v-if="showEditResourcesCountModal"
      @close="closeModal"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  components: {
    EditResourcesCountModal: () =>
      import(`@/components/Modals/EditResourcesCountModal`),
  },

  data() {
    return {
      showEditResourcesCountModal: false,
      userResources: [],
    };
  },

  computed: {
    ...mapGetters("items", ["itemTypesById"]),
  },

  async mounted() {
    this.showLoader(true);
    await this.getItemTypes();
    this.userResources = await this.getUserResources(this.$route.params.id);
    this.showLoader(false);
  },

  methods: {
    ...mapActions("items", ["getItemTypes"]),
    ...mapActions("users", ["getUserResources"]),
    ...mapMutations({
      showLoader: "SET_SHOW_LOADER",
    }),

    async closeModal(needUpdate) {
      this.showEditResourcesCountModal = false;

      if (needUpdate == true) {
        this.showLoader(true);
        this.userResources = await this.getUserResources(this.$route.params.id);
        this.showLoader(false);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .md-table-cell-container {
    white-space: nowrap;
  }
}
</style>
